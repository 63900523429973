"use client";
import Lottie from "lottie-react";
import animationData from "@/utils/animationJson/server_error.json";
import { Button } from "react-bootstrap";
import { useRouter } from "@/navigation";

const Error = () => {
  const router = useRouter();
  return (
    <>
      <div className="d-flex justify-content-center align-items-center position-relative bg-white">
        <Lottie animationData={animationData} className="custom-error-loader" />
      </div>
      <div className="d-flex justify-content-center align-items-center pb-4 ">
        <div className="fw-normal text-black fs-6 px-11 text-center">
          <h5 className="fs-4">Internal Server Error</h5>
          <p className="px-1 fw-normal error-text">
            The server encountered an error and could not complete your request
          </p>
          <div className="d-flex justify-content-center">
            <Button
              onClick={()=>router.push("/")}
              variant="secondary"
              className="outline-none rounded-5 px-4 py-1 mt-3 mt-md-0"
              size="lg"
              style={{ backgroundColor: "#EDEDED", border: "none" }}
            >
              Try Again
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Error;
